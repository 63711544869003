<template>
  <div class="title-wrapper">
    <h1>Help</h1>
  </div>

  <p>
    Email us at <a
      href="mailto:followalong@protonmail.com"
      target="_blank"
      class="link"
    >followalong@protonmail.com</a>.
  </p>
  <p>
    We typically respond to mail once a week.
  </p>
</template>

<script>
export default {
  props: ['app']
}
</script>
